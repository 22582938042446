<template>
  <div class="loader d-flex justify-content-center">
    <b-spinner class="spinnar-top" :variant="variant"></b-spinner>
  </div>
</template>
<script>
export default {
  name: "Loader",
  data() {
    return {
      variant: 'secondary'
    }
  }
};
</script>
<style>
.loader {
  z-index: 999;
  position: fixed;
  background-color: rgb(33 31 31 / 25%);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.loader .text-secondary {
  color: #274BA7 !important;
}

.spinnar-top {
  margin-top: 20rem;
}
</style>