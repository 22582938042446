<template>
  <div>
    <span v-for="item in breadcrumb.breadcrumb" :key="item.name">
      <span class="breadcrumb-ft" :class="{ 'primary-color': item.primary }" @click="navigate(item)">{{
        item.name
      }}</span>
    </span>
    <div class="page-title-cl page-tile">
      {{ breadcrumb.title }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Breadcrumb",
  computed: {
    ...mapGetters(["breadcrumb"])
  },
  beforeCreate() { },
  methods: {
    navigate(obj) {
      console.log(obj)
      if (obj.hasOwnProperty('redirect'))
        this.$router.push(obj.redirect);
    }
  }
};
</script>
<style scoped>
.page-title-cl {
  color: #234372;
}
</style>
