var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getProfileDetails)?_c('div',{staticClass:"my-profile-con"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"img-con"},[(_vm.myDetails.profilePicUrl)?_c('div',[_c('b-img',{staticClass:"pro-img-border",attrs:{"src":_vm.myDetails.profilePicUrl,"rounded":"circle","alt":"Rounded image","width":"75","height":"75"},on:{"error":function($event){return _vm.setAltImg()}}})],1):_c('div',[(_vm.myDetails.userName)?_c('div',{staticClass:"no-img-my"},[_c('span',{staticClass:"profile-sh-my"},[_vm._v(_vm._s(_vm._f("shortName")(_vm.myDetails.userName)))])]):_vm._e()]),_c('div',{staticClass:"details-con"},[_c('div',{staticClass:"profile-name"},[_vm._v(_vm._s(_vm.myDetails.userName))]),(_vm.myDetails.title)?_c('div',{staticClass:"profile-info"},[_vm._v(_vm._s(_vm.myDetails.title))]):_vm._e(),_c('div',{staticClass:"d-flex"},_vm._l((_vm.myDetails.roleDetails),function(roleDetails,index){return _c('div',{key:roleDetails.userRoleId,staticClass:"role-details",class:{
            'role-details-sep': index != _vm.myDetails.roleDetails.length - 1,
          }},[_c('div',{staticClass:"profile-info"},[_c('div',[_vm._v(_vm._s(roleDetails.userRole))]),(roleDetails.userRole != _vm.roles.GLOBALPILLARLEADERNAME && roleDetails.userRole != _vm.roles.CORPORATENAME)?_c('div',[_vm._v(" "+_vm._s(roleDetails.regionName)+" ")]):_vm._e(),(
                roleDetails.userRole == _vm.roles.USERNAMEROLE ||
                roleDetails.userRole == _vm.roles.PLANTSUBPILLARLEADERNAME ||
                roleDetails.userRole == _vm.roles.PLANTPILLARLEADERNAME ||
                roleDetails.userRole == _vm.roles.PLANTPOLEADERNAME ||
                roleDetails.userRole == _vm.roles.PLANTMANAGERNAME
              )?_c('div',[(roleDetails.plantName)?_c('span',[_vm._v(_vm._s(roleDetails.plantName))]):_vm._e()]):_vm._e()]),(_vm.roles.CORPORATENAME != roleDetails.userRole && _vm.roles.REGIONALPOLEADERNAME != roleDetails.userRole
            && _vm.roles.PLANTMANAGERNAME != roleDetails.userRole
            && _vm.roles.PLANTPOLEADERNAME != roleDetails.userRole)?_c('div',[(roleDetails.pillarCode)?_c('span',{staticClass:"pillar"},[_vm._v(_vm._s(roleDetails.pillarCode))]):_vm._e()]):_vm._e()])}),0)])]),_c('div',{staticClass:"pro-con-lt"},[_c('div',{staticClass:"d-flex"},[_c('img',{attrs:{"alt":"phone","src":require("../../assets/phone.svg")}}),_c('span',{staticClass:"profile-info m-left"},[(_vm.myDetails.countryCode)?_c('span',[_vm._v(_vm._s(_vm.myDetails.countryCode)+" - ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.myDetails.contactNumber))])]),_c('div',{staticClass:"d-flex m-top"},[_c('img',{attrs:{"alt":"email","src":require("../../assets/email.svg")}}),_c('span',{staticClass:"profile-info m-left"},[_vm._v(_vm._s(_vm.myDetails.emailId))])])]),_c('div',{staticClass:"col-3"})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }