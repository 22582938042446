<template>
  <div class="my-profile-con" v-if="getProfileDetails">
    <div class="row">
      <div class="img-con">
        <div v-if="myDetails.profilePicUrl">
          <b-img :src="myDetails.profilePicUrl" rounded="circle" alt="Rounded image" width="75" height="75"
            class="pro-img-border" @error="setAltImg()"></b-img>
        </div>
        <div v-else>
          <div class="no-img-my" v-if="myDetails.userName">
            <span class="profile-sh-my">{{
              myDetails.userName | shortName
            }}</span>
          </div>
        </div>
        <div class="details-con">
          <div class="profile-name">{{ myDetails.userName }}</div>
          <div class="profile-info" v-if="myDetails.title">{{ myDetails.title }}</div>
          <div class="d-flex">

            <div class="role-details" :class="{
              'role-details-sep': index != myDetails.roleDetails.length - 1,
            }" v-for="(roleDetails, index) in myDetails.roleDetails" :key="roleDetails.userRoleId">
              <div class="profile-info">
                <div>{{ roleDetails.userRole }}</div>
                <div
                  v-if="roleDetails.userRole != roles.GLOBALPILLARLEADERNAME && roleDetails.userRole != roles.CORPORATENAME">
                  {{ roleDetails.regionName }}
                </div>
                <div v-if="
                  roleDetails.userRole == roles.USERNAMEROLE ||
                  roleDetails.userRole == roles.PLANTSUBPILLARLEADERNAME ||
                  roleDetails.userRole == roles.PLANTPILLARLEADERNAME ||
                  roleDetails.userRole == roles.PLANTPOLEADERNAME ||
                  roleDetails.userRole == roles.PLANTMANAGERNAME
                ">
                  <span v-if="roleDetails.plantName">{{
                    roleDetails.plantName
                  }}</span>
                </div>
              </div>
              <div v-if="roles.CORPORATENAME != roleDetails.userRole && roles.REGIONALPOLEADERNAME != roleDetails.userRole
              && roles.PLANTMANAGERNAME != roleDetails.userRole
              && roles.PLANTPOLEADERNAME != roleDetails.userRole">
                <span class="pillar" v-if="roleDetails.pillarCode">{{
                  roleDetails.pillarCode
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pro-con-lt">
        <div class="d-flex">
          <img alt="phone" src="../../assets/phone.svg" />
          <span class="profile-info m-left"><span v-if="myDetails.countryCode">{{ myDetails.countryCode }} -
            </span>
            {{ myDetails.contactNumber }}</span>
        </div>
        <div class="d-flex m-top">
          <img alt="email" src="../../assets/email.svg" />
          <span class="profile-info m-left">{{ myDetails.emailId }}</span>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>
<script>
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import Role from "../../common/constants/roles";
import Titles from '@/common/constants/titles';
export default {
  name: "MyProfile",
  data() {
    return {
      roles: Role,
      getProfileDetails: false,
      myDetails: {},
      titles: Titles
    };
  },
  created() {
    this.getListOfUsers();
  },
  methods: {
    setAltImg() {
      this.myDetails.profilePicUrl = '';
    },
    getListOfUsers() {
      ApiCalls.httpCallDetails(
        Api.MYPROFILE + this.$oidc.userProfile.unique_name,
        "get"
      ).then((data) => {
        this.myDetails = data.data;
        let titleName = this.myDetails && this.myDetails.title ? this.myDetails.title : ''
        this.myDetails.title = titleName
        // console.log(this.myDetails.title,titleName)
        this.getProfileDetails = true;
      });
    },
  },
};
</script>
<style>
.my-profile-con {
  padding: 2rem;
  border-bottom: 2px solid #e8e8e8;
}

.img-con {
  display: flex;
}

.profile-name {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
}

.profile-info {
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

.pillar {
  font-size: 1.166rem;
  font-family: "BarlowB", sans-serif;
}

.plant-area {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.details-con {
  margin-left: 1.5rem;
  word-break: break-word;
}

.m-left {
  margin-left: 1rem;
}

.m-top {
  margin-top: 0.5rem;
}

.pr-location {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}

.pro-con-lt {
  margin-left: 8rem;
}

.my-profile-con .row {
  margin-right: 0px;
  margin-left: 0px;
}

.m-lt-loc {
  margin-left: 0.5rem;
}

.no-img-my {
  border-radius: 50% !important;
  border: 1px solid #d6d3d3;
  height: 6rem;
  width: 6rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-sh-my {
  font-size: 2rem;
  font-family: "BarlowL", sans-serif;
}

.pro-img-border {
  border: 1px solid #d6d3d3;
}

.user-roles {
  width: 10rem;
}

.role-details {
  margin-right: 1rem;
}

.role-details-sep {
  border-right: 1px solid #d5d5d5;
}
</style>